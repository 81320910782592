import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import defaultTheme from '../theme';

import LanguageProvider from './LanguageProvider';
import { useStaticQuery, graphql } from 'gatsby';

const GlobalSettings = ({ urlLanguage, children }) => {
  const { site } = useStaticQuery(graphql`
    query GlobalSettings {
      site {
        siteMetadata {
          supportedLanguages
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <LanguageProvider
        languages={site.siteMetadata.supportedLanguages}
        urlLanguage={urlLanguage}
      >
        {children}
      </LanguageProvider>
    </ThemeProvider>
  );
};

GlobalSettings.displayName = 'GlobalSettings';

GlobalSettings.propTypes = {
  children: PropTypes.node.isRequired,
};

GlobalSettings.defaultProps = {};

export default GlobalSettings;
