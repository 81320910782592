export const getLanguageFromLocation = ({ pathname }) => {
  return pathname
    .split('/', 2)
    .filter((x) => x !== '')
    .splice(0, 1)
    .pop(); //?
};

export const getBrowserLanguage = () => {
  return typeof navigator !== `undefined`
    ? navigator.language.slice(0, 2)
    : null;
};
