/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'typeface-nunito-sans';

import React from 'react';

import { getLanguageFromLocation } from './src/utils/localization';

import GlobalSettings from './src/components/GlobalSettings';

export const wrapPageElement = ({ element, props }) => {
  return (
    <GlobalSettings urlLanguage={getLanguageFromLocation(props.location)}>
      {element}
    </GlobalSettings>
  );
};
