import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBrowserLanguage } from '../utils/localization';

const Context = createContext();

const LanguageProvider = ({ languages, children, urlLanguage }) => {
  const [current, setCurrent] = useState();

  useEffect(() => {
    setCurrent(urlLanguage || getBrowserLanguage());
  }, [urlLanguage]);

  return (
    <Context.Provider value={[languages, current]}>
      {current && children}
    </Context.Provider>
  );
};

LanguageProvider.displayName = 'LanguageProvider';

LanguageProvider.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  current: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LanguageProvider.defaultProps = {
  current: null,
};

export default LanguageProvider;

export const useCurrentLanguage = () => {
  const [, current] = useContext(Context);
  return current;
};

export const useLanguages = () => {
  const [languages] = useContext(Context);
  return languages;
};
